/*------------
Header Navigation
---------*/
.site-nav .mobile-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: relative;
}
.site-nav .mobile-wrapper > li {
  margin: 0 6px;
}
.site-nav .mobile-wrapper li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.site-nav .mobile-wrapper li:hover > a,
.site-nav .mobile-wrapper li:hover > label,
.site-nav .mobile-wrapper li:hover > svg {
  transition: color 0.2s ease;
  color: #7ab800;
  stroke: #7ab800;
}
.site-nav .mobile-wrapper li label {
  cursor: pointer;
  position: relative;
}
.site-nav .mobile-wrapper li label:focus {
  outline: none;
  color: #7ab800;
}
.site-nav .mobile-wrapper li label svg {
  stroke: #707070;
  transform: rotate(0);
  transition: transform 200ms ease;
  top: -2px;
}
.site-nav .mobile-wrapper li .sub-menu label:after {
  padding-right: 14px;
}
.site-nav .mobile-wrapper li i {
  font-size: 10px;
  margin-left: 5px;
}
.site-nav .mobile-wrapper li a {
  padding: 10px 5px;
  color: #4d4f53;
  display: inline-block;
  width: 100%;
  transition: color 0.2s ease;
}
.site-nav .mobile-wrapper li input[type="checkbox"] {
  display: none;
}
@media (min-width: 650px) {
  .site-nav .mobile-wrapper li.has-submenu.sub-right:hover > .sub-menu,
  .site-nav .mobile-wrapper li.has-submenu.sub-right:focus-within > .sub-menu {
    right: inherit;
    left: inherit;
  }
  .site-nav .mobile-wrapper li.has-submenu:hover > .sub-menu,
  .site-nav .mobile-wrapper li.has-submenu:focus-within > .sub-menu {
    display: inline-flex;
    left: 0px;
    opacity: 1;
    transition: opacity 0.1s linear;
    overflow: visible;
  }
}
.site-nav .mobile-wrapper li input:checked ~ label svg {
  transform: rotate(-180deg);
  stroke: #7ab800 !important;
  top: -2px;
}
.site-nav .mobile-wrapper li input:checked ~ .has-submenu.sub-right .sub-menu {
  right: 0;
  left: inherit;
}
.site-nav .mobile-wrapper li input:checked ~ .sub-menu {
  display: flex;
  left: inherit;
  right: inherit;
  opacity: 1;
  transition: opacity 0.1s linear;
  overflow: visible;
}
.site-nav .mobile-wrapper li input:checked ~ .sub-menu.sub-members-menu {
  right: 0;
}
.site-nav .mobile-wrapper .sub-menu {
  display: inline-flex;
  position: absolute;
  background-color: #ffffff;
  flex-direction: column;
  width: auto;
  left: -99999px;
  top: 100%;
  opacity: 0;
  transition: left 0s 0.1s, opacity 0.1s linear;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
}
.site-nav .mobile-wrapper .sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid rgba(154, 155, 156, 0.25);
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.site-nav .mobile-wrapper .sub-menu li a {
  padding: 15px 15px;
}
.site-nav .mobile-wrapper .sub-menu li:last-child {
  border-bottom: 0;
}

.site-nav {
  background-color: #fff;
  padding: 20px 20px;
  z-index: 100;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.site-nav .nav-logo {
  width: 100%;
  max-width: 100px;
  height: auto;
}
@media (min-width: 960px) {
  .site-nav a {
    display: none !important;
  }
}
.site-nav a {
  display: block;
}
@media screen and (max-width: 600px) {
  .site-nav .burger-menu a:not(:first-child) {
    display: none;
  }
  .site-nav .burger-menu a.icon {
    float: right;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .site-nav .burger-menu.responsive {
    position: relative;
  }
  .site-nav .burger-menu.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .site-nav .burger-menu.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}
.site-nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.site-nav ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-nav ul .text-btn {
  font-size: 16px;
  font-weight: 500;
  color: #4d4f53;
  padding: 10px;
  text-transform: initial;
}
.site-nav ul .text-btn:hover {
  color: #7ab800;
}
.site-nav.expand {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .site-nav.expand {
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .site-nav {
    position: relative;
    width: 100%;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  }
  .site-nav .nav-logo {
    max-width: 120px;
  }
  .site-nav ul {
    justify-content: space-between;
    flex-direction: row;
  }
  .site-nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}
.site-nav .menu-items-wrapper {
  display: flex;
  align-items: center;
}
.site-nav .user-info {
  margin-left: 20px;
  align-content: center;
  flex: 0 0 auto;
  font-size: 14px;
  display: flex;
}
.site-nav .user-info img {
  width: 40px;
  height: auto;
  border-radius: 50%;
}
.site-nav .user-info .user-info-text {
  display: none;
}
.site-nav .user-info .user-name {
  font-weight: 700;
}
@media (min-width: 768px) {
  .site-nav .user-info img {
    width: 45px;
  }
  .site-nav .user-info .user-info-text {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 auto;
  }
}

/*------------
  Header Navigation
  ---------*/
.site-nav .mobile-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  position: relative;
}
.site-nav .mobile-wrapper > li {
  margin: 0 6px;
}
.site-nav .mobile-wrapper li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.site-nav .mobile-wrapper li:hover > a,
.site-nav .mobile-wrapper li:hover > label,
.site-nav .mobile-wrapper li:hover > svg {
  transition: color 0.2s ease;
  color: #7ab800;
  stroke: #7ab800;
}
.site-nav .mobile-wrapper li label {
  cursor: pointer;
  position: relative;
}
.site-nav .mobile-wrapper li label:focus {
  outline: none;
  color: #7ab800;
}
.site-nav .mobile-wrapper li label svg {
  stroke: #707070;
  transform: rotate(0);
  transition: transform 200ms ease;
  top: -2px;
}
.site-nav .mobile-wrapper li .sub-menu label:after {
  padding-right: 14px;
}
.site-nav .mobile-wrapper li i {
  font-size: 10px;
  margin-left: 5px;
}
.site-nav .mobile-wrapper li a {
  padding: 10px 5px;
  color: #4d4f53;
  display: inline-block;
  width: 100%;
  transition: color 0.2s ease;
}
.site-nav .mobile-wrapper li input[type="checkbox"] {
  display: none;
}
@media (min-width: 650px) {
  .site-nav .mobile-wrapper li.has-submenu.sub-right:hover > .sub-menu,
  .site-nav .mobile-wrapper li.has-submenu.sub-right:focus-within > .sub-menu {
    right: inherit;
    left: inherit;
  }
  .site-nav .mobile-wrapper li.has-submenu:hover > .sub-menu,
  .site-nav .mobile-wrapper li.has-submenu:focus-within > .sub-menu {
    display: inline-flex;
    left: 0px;
    opacity: 1;
    transition: opacity 0.1s linear;
    overflow: visible;
  }
}
.site-nav .mobile-wrapper li input:checked ~ label svg {
  transform: rotate(-180deg);
  stroke: #7ab800 !important;
  top: -2px;
}
.site-nav .mobile-wrapper li input:checked ~ .has-submenu.sub-right .sub-menu {
  right: 0;
  left: inherit;
}
.site-nav .mobile-wrapper li input:checked ~ .sub-menu {
  display: flex;
  left: inherit;
  right: inherit;
  opacity: 1;
  transition: opacity 0.1s linear;
  overflow: visible;
}
.site-nav .mobile-wrapper li input:checked ~ .sub-menu.sub-members-menu {
  right: 0;
}
.site-nav .mobile-wrapper .sub-menu {
  display: inline-flex;
  position: absolute;
  background-color: #ffffff;
  flex-direction: column;
  width: auto;
  left: -99999px;
  top: 100%;
  opacity: 0;
  transition: left 0s 0.1s, opacity 0.1s linear;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
}
.site-nav .mobile-wrapper .sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid rgba(154, 155, 156, 0.25);
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.site-nav .mobile-wrapper .sub-menu li a {
  padding: 15px 15px;
}
.site-nav .mobile-wrapper .sub-menu li:last-child {
  border-bottom: 0;
}

.site-nav .mobile-wrapper.app {
  justify-content: center;
  top: -8px;
  position: relative;
}

nav.app ul {
  align-items: start;
}

@media (max-width: 768px) {
  nav.app {
    border-bottom: 1px solid #747679;
  }
}

@media (min-width: 768px) {
  nav.app {
    position: relative;
    padding: 20px 20px;
    width: 100%;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  }
  nav ul {
    justify-content: space-between;
    flex-direction: row;
  }
  nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  nav ul img {
    width: 100px;
  }
  .logo img {
    content: url("./assets/svg/logo.svg") !important;
    min-width: 160px;
  }
}

/*-  Responsive Nav Menu - Hamburger -*/
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* mobile */
@media all and (max-width: 768px) {
  .logo {
    display: none;
  }
}

@media (max-width: 768px) {
  .name {
    position: inherit;
    display: flex;
    flex-basis: auto;
    font-size: 18px;
  }
  .menu {
    justify-content: space-between;
  }
  .site-nav ul.menu {
    justify-content: space-between;
  }
  .page.app {
    max-width: 1240px;
  }
  .main-content {
    padding: 20px 40px 60px;
  }
}

.name span {
  align-self: center;
}

.name img {
  width: 100px;
}

.toggle {
  order: 1;
  padding-top: 10px;
  place-content: flex-end;
  position: absolute;
  top: 20px;
  right: 10px;
}

.toggle-ul {
  position: relative;
  top: -20px;
}
@media (min-width: 960px) {
  .toggle-ul {
    display: none !important;
  }
}

.toggle img {
  align-self: flex-end;
  width: 60px;
}

.item.button {
  order: 3;
}

.item {
  order: 2;
  width: 100%;
  text-align: center;
  display: none;
  font-size: 20px;
  border-top: 1px #c4c4c4 solid;
  padding: 20px 0;
}

.item:first-of-type {
  border-top: 1px #c4c4c4 solid;
}

.item.last {
  border-bottom: 1px #c4c4c4 solid;
  margin-bottom: 40px;
}

.item.cent {
  text-align: -webkit-center;
}

.cents {
  text-align: -webkit-center;
}

.active .item {
  display: block;
}

.saveButton {
  display: none;
}

.item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}

.item.first {
  margin-top: 20px;
}

/* Tablet menu */
@media all and (min-width: 768px) {
  .menu {
    justify-content: center;
  }
  li.logo {
    flex: 1;
    justify-content: left;
    padding-top: 10px;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }
  .item.cent {
    text-align: -webkit-center;
  }
  .toggle {
    flex: 1;
    text-align: right;
    justify-content: right;
    place-content: flex-end;
  }
  .item {
    display: none;
  }
  /* Button up from tablet screen */
  .menu li.button a {
    padding: 10px 15px;
    margin: 5px 0;
    width: max-content;
  }
  .button a {
    background: #fff;
  }
  .button.secondary {
    border: 0;
  }
  .button.secondary a {
    background: transparent;
  }
  .button a:hover {
    text-decoration: none;
  }
  .button:not(.secondary) a:hover {
    background: grey;
    border-color: grey;
  }
  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: flex;
  }
}

/* Desktop menu */
@media all and (min-width: 960px) {
  .menu {
    align-items: flex-start;
    flex-wrap: nowrap;
    background: none;
  }
  .logo {
    order: 0;
  }
  .logo {
    max-width: 300px;
  }
  li.item {
    order: 1;
    position: relative;
    display: none;
    width: auto;
    border-top: 0;
    margin-left: 10px;
  }
  .button {
    order: 2;
  }
  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: flex;
  }
  .name span {
    align-self: center;
  }
  .toggle {
    display: none;
  }
  .saveButton {
    display: contents;
    flex: auto;
  }
}

/*--- END -- responsive menu ---*/
/*--- mobile menu page ---*/
.main-content.app.white {
  background-color: white;
  padding-top: 0px;
}

.mpn {
  width: 100%;
  min-height: 100vh;
  text-align: center;
}
.mpn li {
  border-bottom: 1px solid #d9dae4;
  padding: 24px;
}

/*------
  CSS for Floating Page Layout
  -------*/
body {
  background-color: #fafafa;
}
@media (min-width: 768px) {
  body {
    background-color: #f3f8fc;
  }
}

.background-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #fafafa;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
}
@media (min-width: 768px) {
  .background-wrapper {
    background: url("https://corporate-ux.azurewebsites.net/enrollment/assets/images/somaderm-waterline.jpg")
      top center;
    background-size: cover;
    background-attachment: fixed;
  }
  .background-wrapper:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 0;
  }
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fafafa;
  margin: 0 auto;
  width: 100%;
  border-radius: 0;
  box-shadow: 0;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .page {
    margin: 40px;
    width: 90%;
    max-width: 800px;
    border-radius: 17px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .page {
    margin: 60px;
  }
}

.main-content {
  width: 100%;
  padding: 40px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .main-content {
    padding: 20px 40px 60px;
  }
}
.main-content > section:last-child {
  padding-bottom: 0;
}

/*-----
    Page Titles and sub-content
    -----*/
.intro-text {
  text-align: center;
}
.intro-text h1:only-child {
  margin-bottom: 0;
}
.intro-text p {
  font-size: 18px;
}
.intro-text p:last-child {
  margin-bottom: 0;
}

/*-----
    Step Progress Styles
    -----*/
.step-progress-container {
  background: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  position: initial;
  width: 100%;
}
@media (min-width: 768px) {
  .step-progress-container {
    border-radius: 17px 17px 0 0;
    z-index: 10;
  }
}
.step-progress-container .next-button,
.step-progress-container .back-button {
  position: fixed;
  bottom: 20px;
  z-index: 10;
}
@media (min-width: 768px) {
  .step-progress-container .next-button,
  .step-progress-container .back-button {
    position: relative;
    bottom: initial;
  }
}
.step-progress-container .next-button {
  right: 20px;
}
.step-progress-container .next-button:hover {
  text-decoration: none;
}
.step-progress-container .back-button {
  left: 20px;
}

.page-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  width: 100%;
  background: rgba(250, 250, 250, 0.6);
  background: linear-gradient(0deg, #fafafa 20%, rgba(250, 250, 250, 0) 100%);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  padding: 20px;
}
@media (min-width: 768px) {
  .page-actions {
    border-radius: 0 0 17px 17px;
    position: sticky;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.6);
    background: linear-gradient(0deg, white 20%, rgba(255, 255, 255, 0) 100%);
  }
}
.page-actions .next-button,
.page-actions .back-button {
  position: relative;
}
.page-actions .next-button:hover {
  text-decoration: none;
}

.main-content .space--between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.main-content .pos-relative {
  position: relative;
}

.main-content p.bold {
  font-weight: 600;
}

.main-content p.light {
  color: #9a9b9c;
}

@media (min-width: 768px) {
  .welcome-screen .main-content,
  .main-content.welcome-screen {
    padding-top: 40px;
  }
}

.logged-in .card.sponsor-card {
  padding: 0px 20px 20px;
}
.logged-in .card.sponsor-card .sponsor--id {
  padding-top: 15px;
}

.credit-wrapper .address,
.ach-wrapper .address {
  margin-left: 38px;
}

.mr-0 {
  margin-right: 0;
}

.center {
  align-items: center;
}

.italic {
  font-style: italic;
  font-weight: 400;
}

.align-top {
  align-items: flex-start;
  justify-content: space-between;
}
.align-top .vertical-space {
  display: flex;
  align-items: space-around;
  flex-direction: column;
}

.credits img {
  display: block;
  margin: 0 auto;
}

.credits .ca-total {
  font-weight: 600;
  margin-left: 10px;
}

.credits .quick-input {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(154, 155, 156, 0.18);
}
.credits .quick-input:last-of-type {
  border-bottom: 0px;
}
.credits .quick-input .input-group {
  max-width: 155px;
}
.credits .quick-input .status-circle-s {
  margin-right: 5px;
}

.credit-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 40px;
  justify-items: start;
  align-items: center;
}

@media (min-width: 768px) {
  .credit-grid {
    grid-template-columns: auto 1fr;
  }
  .credits .quick-input {
    border-bottom: 0px;
  }
  .ca-total {
    color: #7ab800;
  }
}

@media (max-width: 374px) {
  .credit-grid {
    justify-items: center;
  }
  .credits .quick-input {
    flex-direction: column;
  }
  .credits .quick-input .input-group {
    max-width: 100%;
  }
  .quick-input .quick-input-success {
    justify-content: center;
    margin: 10px 0;
  }
}

.promo-input {
  align-items: flex-start;
}

.promo-input .text-btn {
  margin-top: 5px;
  padding: 12px;
}

.promo-codes.grid {
  grid-template-columns: auto 1fr;
  justify-items: start;
  grid-column-gap: 20px;
}

@media (max-width: 449px) {
  .flex-20.promo-input {
    flex-direction: column;
    align-items: center;
  }
  .flex-20.promo-input .input-wrapper {
    margin-right: 0;
  }
  .promo-codes.grid p {
    justify-self: end;
  }
}

.space--between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.green {
  color: #7ab800;
}

.red {
  color: #ba0c2f;
}

.dark-orange {
  color: #9a3b26;
}

.dark-green {
  color: #427730;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.font-14 {
  font-size: 14px;
}

.w-max-content {
  max-width: max-content;
  width: initial;
}

.flex-start-svg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-start-svg svg {
  margin-right: 10px;
}

.content--footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.add-products .card .inner-content {
  padding-bottom: 0;
}

.card .inner-content .product-quantity-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto;
  border: 3px solid transparent;
  transition: all 200ms ease;
}
.card .inner-content .product-quantity-card.active {
  border: 3px solid #7ab800;
}
.card .inner-content .product-quantity-card .product-info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.card .inner-content .product-quantity-card .product-info-wrapper img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  margin: 0;
  padding: 0;
}
.card
  .inner-content
  .product-quantity-card
  .product-info-wrapper
  .product-info {
  flex: 1 1;
  margin-left: 20px;
}
.card
  .inner-content
  .product-quantity-card
  .product-info-wrapper
  .product-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4d4f53;
  margin-bottom: 10px;
}
.card .inner-content .product-quantity-card .product-info-wrapper ul {
  list-style: none;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.card .inner-content .product-quantity-card .product-info-wrapper ul li {
  color: #747679;
  line-height: 20px;
}
.card
  .inner-content
  .product-quantity-card
  .product-info-wrapper
  .product-price {
  color: #4d4f53;
  font-size: 20px;
  line-height: 20px;
}
@media (min-width: 449px) {
  .card
    .inner-content
    .product-quantity-card
    .product-info-wrapper
    .product--card--content--inner {
    width: 140px;
  }
}
.card .inner-content .product-quantity-card .card--footer {
  border-top: 1px solid #f2f2f2;
  margin: auto auto 0;
  width: 100%;
}
.card
  .inner-content
  .product-quantity-card
  .card--footer.space--between
  span.dark-green,
.card
  .inner-content
  .product-quantity-card
  .card--footer.space--between
  span.gray {
  font-size: 14px;
  width: 120px;
}
.card
  .inner-content
  .product-quantity-card
  .card--footer.space--between
  span.gray {
  color: rgba(154, 155, 156, 0.5);
}

.flex-start {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/*---------
  Review Screen Specific
  ----------*/
@media (min-width: 768px) {
  .review-screen .text-definition {
    right: 0;
    left: initial;
  }
}

.total-cost {
  margin-left: 10px;
  flex: 0 0 auto;
}

/* --------- Ghost Button White Bkg ------*/
.button.blue.ghost {
  background-color: #ffffff;
}
.button.blue.ghost:hover {
  background-color: #007ec3;
  border-color: #007ec3;
}

/* --------- Order Summary Items -------*/
p.sub-text {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.order-summary p.light {
  color: #9a9b9c;
}

.order-summary p.bold {
  color: #4d4f53;
  font-weight: 600;
}

.order-summary p.red {
  color: #ba0c2f;
}

.order-summary .border-top-bottom {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}

.order-summary .double-border-bottom {
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 4px 0px 0px white, 0px 5px 0px 0px #ededed;
}

.order-summary .border-bottom {
  border-bottom: 1px solid #ededed;
}

.order-summary .padding-10 {
  padding: 10px 0;
}

.order-summary .total-review-summary p {
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
@media (min-width: 768px) {
  .order-summary .total-review-summary p {
    justify-content: space-between;
    width: 75%;
    margin-left: auto;
  }
  .order-summary .total-review-summary p > span:first-of-type {
    text-align: right;
    width: 70%;
  }
}

.order-summary .total-review-summary .review-row {
  padding: 20px 0;
}
.order-summary .total-review-summary .review-row:last-of-type {
  padding: 10px 0;
  margin-bottom: 20px;
}

.order-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.order-cell img {
  object-fit: contain;
  height: 90px;
  width: 90px;
  margin-right: 15px;
}
@media (min-width: 570px) {
  .order-cell img {
    height: 100px;
    width: 100px;
    margin-right: 20px;
  }
}
.order-cell .order-desc {
  flex: 1 1 auto;
  display: grid;
  grid-gap: 5px;
}
.order-cell .order-desc p {
  margin: 0;
}
.order-cell .order-desc .product-title {
  margin-bottom: 5px;
  font-weight: 500;
}
.order-cell .order-desc .product-cv {
  color: #9a9b9c;
  margin-bottom: 7px;
}
.order-cell .order-desc .product-quantity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
@media (min-width: 570px) {
  .order-cell .order-desc .product-quantity {
    justify-content: flex-end;
    margin-top: 5px;
  }
}
.order-cell .order-desc .product-price {
  text-align: left;
}
@media (min-width: 570px) {
  .order-cell .order-desc .product-price {
    margin-left: auto;
    text-align: right;
    font-size: 18px;
  }
}
@media (min-width: 570px) {
  .order-cell .order-desc {
    grid-template-columns: 1fr 1fr;
  }
  .order-cell .order-desc .product-title,
  .order-cell .order-desc .product-cv {
    margin-bottom: 0;
  }
  .order-cell .order-desc .product-title {
    align-self: end;
  }
  .order-cell .order-desc .product-cv {
    align-self: start;
  }
}
@media (min-width: 570px) {
  .order-cell .order-desc {
    grid-template-columns: 4fr 2.5fr 3.5fr;
  }
  .order-cell .order-desc .product-title {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .order-cell .order-desc .product-price {
    grid-row-start: span 2;
    grid-column-start: 3;
    align-self: center;
    order: 4;
  }
  .order-cell .order-desc .product-cv {
    grid-row-start: 2;
    grid-row-end: 3;
    order: 2;
    align-self: center;
  }
  .order-cell .order-desc .product-quantity {
    grid-row-start: span 2;
    grid-column-start: 2;
    order: 3;
    align-self: center;
  }
}
.order-cell a {
  max-width: max-content;
}

@media (min-width: 570px) {
  .order-desc.condensed {
    grid-template-columns: 2fr 1fr;
  }
  .order-desc.condensed .product-title {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .order-desc.condensed .product-price {
    grid-row-start: 1;
    grid-column-start: 2;
    order: 2;
    font-size: 16px;
  }
  .order-desc.condensed .product-cv {
    grid-row-start: 2;
    grid-row-end: 3;
    order: 3;
  }
  .order-desc.condensed .product-quantity {
    grid-row-start: 2;
    grid-column-start: 2;
    order: 4;
  }
}

/*----Global Alert Overrides---*/
.global-alert {
  bottom: 100%;
  position: absolute;
}
@media (min-width: 768px) {
  .global-alert {
    right: 40px;
    top: auto;
  }
}

/*--- Starter Kit Screen -- */
.starter-kit-screen .main-content section.hide {
  padding-bottom: 0;
}

/*---- Review Screen ---*/
.review-screen h1 {
  margin-bottom: 0;
}

.review-screen .strike-through {
  color: #747679;
}

.review-screen .green-alert {
  margin: 20px auto 0 auto;
}
@media (min-width: 768px) {
  .review-screen .green-alert {
    width: 50%;
    margin: 20px 0 0 auto;
  }
}

.table-title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
}
.table-title p {
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 700;
}
.table-title a,
.table-title button {
  margin: -10px 0 0 10px;
  padding: 10px;
}

.table-header {
  display: none;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
  border-bottom: 1px solid #ededed;
  box-shadow: 0px 4px 0px 0px white, 0px 5px 0px 0px #ededed;
}
.table-header p {
  line-height: 1;
  margin: 0 0 10px;
}
@media (min-width: 570px) {
  .table-header {
    display: grid;
  }
}

/*---- Address form card overrides ---*/
.card .center p.address-country {
  margin-bottom: 20px;
}

#section-account-address {
  margin-top: 20px;
}

/*---- Modal Overrides --*/
@media (max-width: 649px) {
  .modal button.accordion.is-open + .accordion-content .grid.tab-card,
  .modal .modal--starter-kit-wrapper .grid.tab-card {
    grid-template-columns: 1fr;
  }
}

.modal
  button.accordion.is-open
  + .accordion-content
  .grid.tab-card
  .product--card,
.modal .modal--starter-kit-wrapper .grid.tab-card .product--card {
  text-align: center;
}
.modal
  button.accordion.is-open
  + .accordion-content
  .grid.tab-card
  .product--card
  .flex-20,
.modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20 {
  flex-direction: column;
  align-items: center;
}
.modal
  button.accordion.is-open
  + .accordion-content
  .grid.tab-card
  .product--card
  .flex-20
  > *,
.modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20 > * {
  margin-right: 0;
}
@media (min-width: 1024px) {
  .modal
    button.accordion.is-open
    + .accordion-content
    .grid.tab-card
    .product--card
    .product-info
    .product-title,
  .modal
    button.accordion.is-open
    + .accordion-content
    .grid.tab-card
    .product--card
    .product-info
    strong,
  .modal
    button.accordion.is-open
    + .accordion-content
    .grid.tab-card
    .product--card
    .product-info
    ul,
  .modal
    .modal--starter-kit-wrapper
    .grid.tab-card
    .product--card
    .product-info
    .product-title,
  .modal
    .modal--starter-kit-wrapper
    .grid.tab-card
    .product--card
    .product-info
    strong,
  .modal
    .modal--starter-kit-wrapper
    .grid.tab-card
    .product--card
    .product-info
    ul {
    text-align: center;
  }
  .modal
    button.accordion.is-open
    + .accordion-content
    .grid.tab-card
    .product--card
    .product-info
    ul
    li,
  .modal
    .modal--starter-kit-wrapper
    .grid.tab-card
    .product--card
    .product-info
    ul
    li {
    max-width: 100%;
  }
}

@media (max-width: 449px) {
  .modal .card .inner-content .product--small .product--card--content {
    flex-direction: column;
  }
  .modal
    .card
    .inner-content
    .product--small
    .product--card--content
    .product--card--content--inner {
    text-align: center;
  }
  .modal .card .inner-content .product--small .product--card--content img {
    margin-bottom: 20px;
  }
}

#confirm-address-modal .modal-content .center .blue--switch--container {
  min-width: 220px;
}

.hide {
  height: 0;
  opacity: 0;
  transition: visibility 200ms linear, opacity 200ms linear;
  z-index: -2;
  visibility: hidden;
  position: absolute;
  left: -99999px;
  overflow: hidden;
}

.reveal,
.hide.reveal {
  height: initial;
  opacity: 1;
  z-index: initial;
  visibility: visible;
  position: initial;
  left: initial;
  overflow: initial;
}

/*----------
  // Misc overrides
  ----------*/
.info-tooltip-wrapper .info-button {
  background-color: #fafafa;
}
@media (min-width: 768px) {
  .info-tooltip-wrapper .info-button {
    background: #ffffff;
  }
}

.success .green-alert {
  max-width: max-content;
  text-align: left;
  flex-direction: column;
  margin-bottom: 20px;
}
.success .green-alert > :first-child {
  margin-bottom: 10px;
}
.success .green-alert > p {
  font-weight: 400;
}
@media (max-width: 449px) {
  .success .green-alert {
    max-width: 100%;
  }
  .success .green-alert > p {
    font-size: 16px;
    word-break: break-all;
    display: flex;
    flex-direction: column;
  }
}
