.page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  margin: 0 auto;
  width: 100%;
  border-radius: 0;
  box-shadow: 0;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .page {
    margin: 40px auto;
    width: 90%;
    max-width: 800px;
    border-radius: 17px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  .page {
    margin: 60px auto;
  }
}

.main-content {
  width: 100%;
  padding: 40px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.main-content > section:last-child {
  padding-bottom: 0;
}
